@import "https://fonts.googleapis.com/css?family=Inconsolata:400,700|Roboto+Mono:400,500|Roboto:300,400,500,500i&display=swap";
.DayPicker {
  display: inline-block;
}

.DayPicker-wrapper {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  padding-bottom: 1rem;
  position: relative;
}

.DayPicker-Months {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
  user-select: none;
  margin: 1rem 1rem 0;
  display: table;
}

.DayPicker-NavButton {
  cursor: pointer;
  color: #8b9898;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2px;
  display: inline-block;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  margin-right: 1.5rem;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: left;
  margin-bottom: .5rem;
  padding: 0 .5rem;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 1.15rem;
  font-weight: 500;
}

.DayPicker-Weekdays {
  margin-top: 1rem;
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  text-align: center;
  color: #8b9898;
  padding: .5rem;
  font-size: .875em;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  outline: none;
  padding: .5rem;
  display: table-cell;
}

.DayPicker-WeekNumber {
  text-align: right;
  vertical-align: middle;
  cursor: pointer;
  color: #8b9898;
  border-right: 1px solid #eaecec;
  min-width: 1rem;
  padding: .5rem;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5rem;
}

.DayPicker-TodayButton {
  box-shadow: none;
  cursor: pointer;
  color: #4a90e2;
  background-color: #0000;
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #f0f8ff;
  background-color: #4a90e2;
  border-radius: 100%;
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
  border-radius: 50%;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 5px #00000026;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  color: #4a90e2;
  background-color: #f0f8ff !important;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.hljs {
  color: #333;
  background: #fff;
  padding: .5em;
  display: block;
  overflow-x: auto;
}

.hljs-comment, .hljs-meta {
  color: #969896;
}

.hljs-variable, .hljs-template-variable, .hljs-strong, .hljs-emphasis, .hljs-quote {
  color: #df5000;
}

.hljs-keyword, .hljs-selector-tag, .hljs-type {
  color: #d73a49;
}

.hljs-literal, .hljs-symbol, .hljs-bullet, .hljs-attribute {
  color: #0086b3;
}

.hljs-section, .hljs-name {
  color: #63a35c;
}

.hljs-tag {
  color: #333;
}

.hljs-title, .hljs-attr, .hljs-selector-id, .hljs-selector-class, .hljs-selector-attr, .hljs-selector-pseudo {
  color: #6f42c1;
}

.hljs-addition {
  color: #55a532;
  background-color: #eaffea;
}

.hljs-deletion {
  color: #bd2c00;
  background-color: #ffecec;
}

.hljs-link {
  text-decoration: underline;
}

.hljs-number {
  color: #005cc5;
}

.hljs-string {
  color: #032f62;
}

.replayer-wrapper {
  position: relative;
}

.replayer-mouse {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwMCIgd2lkdGg9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkYXRhLW5hbWU9IkxheWVyIDEiIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHBhdGggZD0iTTQ4LjcxIDQyLjkxTDM0LjA4IDI4LjI5IDQ0LjMzIDE4YTEgMSAwIDAwLS4zMy0xLjYxTDIuMzUgMS4wNmExIDEgMCAwMC0xLjI5IDEuMjlMMTYuMzkgNDRhMSAxIDAgMDAxLjY1LjM2bDEwLjI1LTEwLjI4IDE0LjYyIDE0LjYzYTEgMSAwIDAwMS40MSAwbDQuMzgtNC4zOGExIDEgMCAwMC4wMS0xLjQyem0tNS4wOSAzLjY3TDI5IDMyYTEgMSAwIDAwLTEuNDEgMGwtOS44NSA5Ljg1TDMuNjkgMy42OWwzOC4xMiAxNEwzMiAyNy41OEExIDEgMCAwMDMyIDI5bDE0LjU5IDE0LjYyeiIvPjwvc3ZnPg==");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #0000;
  width: 20px;
  height: 20px;
  transition: left 50ms linear, top 50ms linear;
  position: absolute;
}

.replayer-mouse:after {
  content: "";
  opacity: .3;
  background: #4950f6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
  transform: translate(-50%, -50%);
}

.replayer-mouse.active:after {
  animation: .2s ease-in-out click;
}

.replayer-mouse.touch-device {
  background-image: none;
  border: 4px solid #4950f600;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  margin-top: -37px;
  margin-left: -37px;
  transition: left linear, top linear, border-color .2s ease-in-out;
}

.replayer-mouse.touch-device.touch-active {
  border-color: #4950f6;
  transition: left .25s linear, top .25s linear, border-color .2s ease-in-out;
}

.replayer-mouse.touch-device:after {
  opacity: 0;
}

.replayer-mouse.touch-device.active:after {
  animation: .2s ease-in-out touch-click;
}

.replayer-mouse-tail {
  pointer-events: none;
  position: absolute;
}

@keyframes click {
  0% {
    opacity: .3;
    width: 20px;
    height: 20px;
  }

  50% {
    opacity: .5;
    width: 10px;
    height: 10px;
  }
}

@keyframes touch-click {
  0% {
    opacity: 0;
    width: 20px;
    height: 20px;
  }

  50% {
    opacity: .5;
    width: 10px;
    height: 10px;
  }
}

.rr-player {
  float: left;
  background: #fff;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 24px 48px #11103e1f;
}

.rr-player__frame {
  overflow: hidden;
}

.replayer-wrapper {
  float: left;
  clear: both;
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
}

.replayer-wrapper > iframe {
  border: none;
}

.rr-controller.svelte-19ke1iv.svelte-19ke1iv {
  background: #fff;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  display: flex;
}

.rr-timeline.svelte-19ke1iv.svelte-19ke1iv {
  align-items: center;
  width: 80%;
  display: flex;
}

.rr-timeline__time.svelte-19ke1iv.svelte-19ke1iv {
  text-align: center;
  color: #11103e;
  width: 100px;
  display: inline-block;
}

.rr-progress.svelte-19ke1iv.svelte-19ke1iv {
  cursor: pointer;
  box-sizing: border-box;
  background: #eee;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-radius: 3px;
  flex: 1;
  height: 12px;
  position: relative;
}

.rr-progress.disabled.svelte-19ke1iv.svelte-19ke1iv {
  cursor: not-allowed;
}

.rr-progress__step.svelte-19ke1iv.svelte-19ke1iv {
  background: #e0e1fe;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rr-progress__handler.svelte-19ke1iv.svelte-19ke1iv {
  background: #4950f6;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  transform: translate(-50%, -50%);
}

.rr-controller__btns.svelte-19ke1iv.svelte-19ke1iv {
  justify-content: center;
  align-items: center;
  font-size: 13px;
  display: flex;
}

.rr-controller__btns.svelte-19ke1iv button.svelte-19ke1iv {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
}

.rr-controller__btns.svelte-19ke1iv button.svelte-19ke1iv:active {
  background: #e0e1fe;
}

.rr-controller__btns.svelte-19ke1iv button.active.svelte-19ke1iv {
  color: #fff;
  background: #4950f6;
}

.rr-controller__btns.svelte-19ke1iv button.svelte-19ke1iv:disabled {
  cursor: not-allowed;
}

.switch.svelte-9brlez.svelte-9brlez.svelte-9brlez {
  align-items: center;
  height: 1em;
  display: flex;
}

.switch.disabled.svelte-9brlez.svelte-9brlez.svelte-9brlez {
  opacity: .5;
}

.label.svelte-9brlez.svelte-9brlez.svelte-9brlez {
  margin: 0 8px;
}

.switch.svelte-9brlez input[type="checkbox"].svelte-9brlez.svelte-9brlez {
  opacity: 0;
  position: absolute;
}

.switch.svelte-9brlez label.svelte-9brlez.svelte-9brlez {
  cursor: pointer;
  width: 2em;
  height: 1em;
  display: block;
  position: relative;
}

.switch.disabled.svelte-9brlez label.svelte-9brlez.svelte-9brlez {
  cursor: not-allowed;
}

.switch.svelte-9brlez label.svelte-9brlez.svelte-9brlez:before {
  content: "";
  background: #4950f680;
  border-radius: 50px;
  width: 2em;
  height: 1em;
  transition: background .1s;
  position: absolute;
  left: .1em;
}

.switch.svelte-9brlez label.svelte-9brlez.svelte-9brlez:after {
  content: "";
  z-index: 2;
  background: #fcfff4;
  border-radius: 50px;
  width: 1em;
  height: 1em;
  transition: all .2s;
  animation: .2s ease-out switch-off;
  position: absolute;
  left: 0;
  box-shadow: 0 2px 5px #0000004d;
}

.switch.svelte-9brlez input[type="checkbox"].svelte-9brlez:checked + label.svelte-9brlez:before {
  background: #4950f6;
}

.switch.svelte-9brlez input[type="checkbox"].svelte-9brlez:checked + label.svelte-9brlez:after {
  animation: .2s ease-out switch-on;
  left: 1.1em;
}

.react-grid-layout {
  transition: height .2s;
  position: relative;
}

.react-grid-item {
  transition: left .2s, top .2s;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  z-index: 3;
  will-change: transform;
  transition: none;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  opacity: .2;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  background: red;
  transition-duration: .1s;
}

.react-grid-item > .react-resizable-handle {
  width: 20px;
  height: 20px;
  position: absolute;
}

.react-grid-item > .react-resizable-handle:after {
  content: "";
  border-bottom: 2px solid #0006;
  border-right: 2px solid #0006;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w, .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n, .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v46/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

html {
  font-size: 16px;
}

button {
  text-transform: none !important;
}

.material-icons {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: Material Icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

body {
  width: 100%;
  font-family: Roboto;
  overflow-x: hidden;
}

input, textarea, select, button {
  font-family: Roboto;
}

a {
  color: #007bf9;
  text-decoration: none;
}

a:visited, a:hover {
  color: #007bf9;
  text-decoration: underline;
}

.project-settings-textfield > div:after, .project-settings-textfield fieldset[class*="focused"], .project-settings-select:after {
  border-color: #54b8ea !important;
}

.project-settings-textfield > label {
  color: #0000008a !important;
}

.textfield-number input {
  text-align: right;
}

.settings-textfield > label {
  left: .5em !important;
}

.settings-textfield > div > input {
  padding-left: .5em !important;
}

.dropzone-svg-container svg, .extra-large-icon svg {
  font-size: 90px;
}

.login-container input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

a:hover {
  text-decoration: underline;
}

.attributes-table-row:hover .action-button, .list-item:hover .action-button {
  display: inline-flex !important;
}

.link:hover {
  text-decoration: underline;
}

.no-hover:hover {
  background-color: unset !important;
}

.saved-query-search-input:focus {
  outline: none;
}

.saved-query-item:hover {
  background-color: #eee;
}

.saved-query-item:hover .saved-query-item-action {
  display: block !important;
}

.attributes-table-row:hover {
  background-color: #eee !important;
}

.full-screen-aggs-table {
  width: 100vw !important;
}

.hide {
  display: none !important;
}

.no-overflow {
  overflow-y: hidden !important;
}

.typografold {
  width: 100%;
}

.typografold .typografold-start:before, .typografold-start:after, .typografold .typografold-end:before, .typografold-end:after {
  white-space: pre;
  max-width: 50%;
  display: inline-block;
  overflow: hidden;
}

.typografold .typografold-start:before {
  content: attr(data);
  text-overflow: ellipsis;
}

.typografold .typografold-end:after {
  content: attr(data);
  text-overflow: ellipsis;
  direction: rtl;
}

.table-column-header > div {
  padding-left: 8px;
}

.table-container thead {
  box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
}

.inbox-table-row-hidden-unless-hover {
  opacity: 0;
}

.inbox-table-row:hover .inbox-table-row-hidden-unless-hover {
  opacity: 1;
}

.inbox-table-row-hidden {
  opacity: 0;
}

.table-overlay {
  z-index: 1000;
  background-color: #0000002e;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.absolute {
  position: absolute;
}

.inbox-detail-header-reapply-filters {
  opacity: 0;
}

.inbox-detail-header:hover .inbox-detail-header-reapply-filters {
  opacity: 1;
}

.smaller-icon svg {
  font-size: 18px;
}

.extra-smaller-icon svg {
  font-size: 12px;
}

.inbox-table-row {
  transition: box-shadow .1s;
}

.inbox-table-row:hover {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.integration-guide-markdown h1 {
  color: #0000008a;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.20588em;
}

.integration-guide-markdown h2 {
  color: #000000de;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.35417em;
}

.integration-guide-markdown h3, .integration-guide-markdown p {
  color: #000000de;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
}

.integration-guide-markdown pre {
  word-wrap: break-word;
  word-break: break-word;
  background-color: #eee;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px 15px;
  overflow: auto;
}

.integration-guide-markdown code {
  color: #2a363b;
  background-color: #eee;
  border-radius: 3px;
  padding: 1px 2px;
  font-family: Inconsolata, monospace;
  font-size: 1em;
}

.integration-guide-markdown a:hover {
  text-decoration: underline;
}

.hover-darken:hover {
  filter: brightness(85%);
  background-color: inherit;
}

.remove-after:after {
  content: none;
  border: none;
}

.explore-table-paper [class*="Pager-pager"] {
  border-top: 1px solid #e3e2e3;
}

.explore-table-paper [class*="Pagination-activeButton"] {
  background-color: #273868;
  color: #fafafa !important;
}

.explore-table-paper [class*="Pagination-activeButton"]:hover {
  color: #232323 !important;
}

[role="tooltip"] {
  z-index: 1301;
}

.top-z {
  z-index: 10000;
}

.flamegraph svg g div {
  font-family: Inconsolata, monospace !important;
}

.flamegraph svg g rect:hover {
  fill: #007bf9;
}

.flamegraph svg g:hover div {
  color: #fff !important;
}

.flamegraph div {
  width: 100% !important;
  overflow-x: hidden !important;
}

.ui-select:-moz-focusring {
  color: currentColor !important;
}

.filter-input-bar {
  transition: height .2s linear;
}

[class*="TableDetailCell-active"] {
  padding: 0 !important;
}

.triage-table-root td {
  background-color: inherit !important;
}

.triage-table-root .triage-action-cell {
  background-color: #0000 !important;
}

.triage-table-row:hover .triage-action-cell__active {
  background-color: inherit !important;
}

.triage-table-row:hover .triage-action-cell {
  padding: 0;
}

.triage-table-row:hover .triage-action-cell > div {
  background-image: linear-gradient(to right, #fff0 0%, #fff 15%);
  justify-content: center;
  align-items: center;
  height: 48px;
  display: flex;
}

.triage-table-row:hover.stripe .triage-action-cell > div {
  background-image: linear-gradient(to right, #fff0 0%, #f6f7f8 15%);
  justify-content: center;
  align-items: center;
  height: 48px;
  display: flex;
}

.triage-table-row:hover.selected .triage-action-cell > div {
  background-image: linear-gradient(to right, #fff0 0%, #fffcee 15%);
  justify-content: center;
  align-items: center;
  height: 48px;
  display: flex;
}

.triage-table-row.stripe .multi-action-container {
  background-image: linear-gradient(to right, #fff0 0%, #f6f7f8 15%);
}

.triage-table-row.selected .multi-action-container {
  background-image: linear-gradient(to right, #fff0 0%, #fffcee 15%);
}

.triage-table-row .multi-action-container {
  background-image: linear-gradient(to right, #fff0 0%, #fff 15%);
}

.triage-table-root td:first-child {
  padding: 0 8px 0 0 !important;
}

.triage-table-root th:first-child {
  padding-left: 5px !important;
}

[x-placement*="bottom"] div.arrow:before {
  content: "";
  border: 1em solid #0000;
  border-top-width: 0;
  border-bottom-color: #616161;
  width: 0;
  height: 0;
  margin-top: -.95em;
  position: absolute;
  top: 0;
  left: calc(50% - 1em);
}

[x-placement*="top"] div.arrow:before {
  content: "";
  border: 1em solid #0000;
  border-top-color: #616161;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-bottom: -.95em;
  position: absolute;
  bottom: 0;
  left: calc(50% - 1em);
}

#column-drag-image {
  color: #fff;
  cursor: grabbing;
  background-color: #007bf9;
  border-radius: 50px;
  padding: 8px;
  font-size: 13px;
  position: absolute;
  top: -1500px;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}
/*# sourceMappingURL=main.css.map */
