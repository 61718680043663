@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700|Roboto+Mono:400,500|Roboto:300,400,500,500i&display=swap");

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v46/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

html {
  font-size: 16px;
}

button {
  text-transform: none !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: Roboto;
  width: 100%;
  overflow-x: hidden;
}

input,
textarea,
select,
button {
  font-family: Roboto;
}

a {
  color: #007bf9;
  text-decoration: none;
}

a:visited,
a:hover {
  text-decoration: underline;
  color: #007bf9;
}

.project-settings-textfield > div:after {
  border-color: #54b8ea !important;
}

.project-settings-textfield fieldset[class*="focused"] {
  border-color: #54b8ea !important;
}

.project-settings-select:after {
  border-color: #54b8ea !important;
}

.project-settings-textfield > label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.textfield-number input {
  text-align: right;
}

.settings-textfield > label {
  left: 0.5em !important;
}

.settings-textfield > div > input {
  padding-left: 0.5em !important;
}

.dropzone-svg-container svg {
  font-size: 90px;
}

.extra-large-icon svg {
  font-size: 90px;
}

.login-container input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

a:hover {
  text-decoration: underline;
}

.attributes-table-row:hover .action-button {
  display: inline-flex !important;
}

.list-item:hover .action-button {
  display: inline-flex !important;
}

.link:hover {
  text-decoration: underline;
}

.no-hover:hover {
  background-color: unset !important;
}

.saved-query-search-input:focus {
  /* outline-color: #6777a4; */
  outline: none;
}

.saved-query-item:hover {
  background-color: #eeeeee;
}

.saved-query-item:hover .saved-query-item-action {
  display: block !important;
}

.attributes-table-row:hover {
  background-color: #eeeeee !important;
}

.full-screen-aggs-table {
  width: 100vw !important;
}

.hide {
  display: none !important;
}

.no-overflow {
  overflow-y: hidden !important;
}

.typografold {
  width: 100%;
}

.typografold .typografold-start::before,
.typografold-start::after {
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  white-space: pre;
}

.typografold .typografold-end::before,
.typografold-end::after {
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  white-space: pre;
}

.typografold .typografold-start::before {
  content: attr(data);
  text-overflow: ellipsis;
}

.typografold .typografold-end::after {
  content: attr(data);
  text-overflow: ellipsis;
  direction: rtl;
}

.table-column-header > div {
  padding-left: 8px;
}

/* .table-column-header--callstack > div {
  padding-left: 21px;
} */

.table-container thead {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.inbox-table-row-hidden-unless-hover {
  opacity: 0;
}

.inbox-table-row:hover .inbox-table-row-hidden-unless-hover {
  opacity: 1;
}

.inbox-table-row-hidden {
  opacity: 0;
}

.table-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

.absolute {
  position: absolute;
}

.inbox-detail-header-reapply-filters {
  opacity: 0;
}

.inbox-detail-header:hover .inbox-detail-header-reapply-filters {
  opacity: 1;
}

.smaller-icon svg {
  font-size: 18px;
}

.extra-smaller-icon svg {
  font-size: 12px;
}

.inbox-table-row {
  -webkit-transition: box-shadow 0.1s;
  transition: box-shadow 0.1s;
}

.inbox-table-row:hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.integration-guide-markdown h1 {
  color: rgba(0, 0, 0, 0.54);
  font-size: 2.125rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.20588em;
}

.integration-guide-markdown h2 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.35417em;
}

.integration-guide-markdown h3 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5em;
}

.integration-guide-markdown p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5em;
}

.integration-guide-markdown pre {
  background-color: #eeeeee;
  padding: 20px 15px;
  border-radius: 4px;
  overflow: auto;
  word-wrap: break-word;
  word-break: break-word;
  border: solid 1px #e0e0e0;
}

.integration-guide-markdown code {
  color: #2a363b;
  background-color: #eeeeee;
  padding: 1px 2px;
  font-family: Inconsolata, monospace;
  font-size: 1em;
  border-radius: 3px;
}

.integration-guide-markdown a:hover {
  text-decoration: underline;
}

.hover-darken:hover {
  filter: brightness(85%);
  background-color: inherit;
}

.remove-after::after {
  content: none;
  border: none;
}

.explore-table-paper [class*="Pager-pager"] {
  border-top: 1px solid #e3e2e3;
}

.explore-table-paper [class*="Pagination-activeButton"] {
  background-color: #273868;
  color: #fafafa !important;
}

.explore-table-paper [class*="Pagination-activeButton"]:hover {
  color: #232323 !important;
}

/* This is for the typeahead dropdown because material UI's header has a value of 1100 */
[role="tooltip"] {
  z-index: 1301;
}

.top-z {
  z-index: 10000;
}

.flamegraph svg g div {
  font-family: Inconsolata, monospace !important;
}

.flamegraph svg g rect:hover {
  fill: #007bf9;
}

.flamegraph svg g:hover div {
  color: white !important;
}

.flamegraph div {
  overflow-x: hidden !important;
  width: 100% !important;
}

.ui-select:-moz-focusring {
  color: currentColor !important;
}

.filter-input-bar {
  transition: height 0.2s linear;
}

[class*="TableDetailCell-active"] {
  padding: 0 !important;
}

.triage-table-root td {
  background-color: inherit !important;
}

.triage-table-root .triage-action-cell {
  background-color: transparent !important;
}

/* .triage-table-row:hover .triage-action-cell {
  background-color: inherit !important;
} */

.triage-table-row:hover .triage-action-cell__active {
  background-color: inherit !important;
}

.triage-table-row:hover .triage-action-cell {
  padding: 0;
}

.triage-table-row:hover .triage-action-cell > div {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%
  );
}

.triage-table-row:hover.stripe .triage-action-cell > div {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(246, 247, 248, 1) 15%
  );
}

.triage-table-row:hover.selected .triage-action-cell > div {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 252, 238, 1) 15%
  );
}

.triage-table-row.stripe .multi-action-container {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(246, 247, 248, 1) 15%
  );
}

.triage-table-row.selected .multi-action-container {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 252, 238, 1) 15%
  );
}

.triage-table-row .multi-action-container {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%
  );
}

.triage-table-root td:first-child {
  padding: 0 8px 0 0 !important;
}

.triage-table-root th:first-child {
  padding-left: 5px !important;
}

[x-placement*="bottom"] div.arrow::before {
  position: absolute;
  content: "";
  top: 0;
  left: calc(50% - 1em);
  margin-top: -0.95em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1em 1em 1em;
  border-color: transparent transparent #616161 transparent;
}

[x-placement*="top"] div.arrow::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: calc(50% - 1em);
  margin-bottom: -0.95em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1em 1em 0 1em;
  border-color: #616161 transparent transparent transparent;
}

#column-drag-image {
  background-color: #007bf9;
  color: white;
  padding: 8px;
  font-size: 13px;
  border-radius: 50px;
  position: absolute;
  top: -1500px;
  cursor: grabbing;
}

/* 

  Whenever adding a walkthrough selector, 
  be sure to ADD to the following list and test 
  yourself by uncommenting the follow.

  Also you must use the following format
  walkthrough--[view_and_or_section]--[specific_element].
  See below for examples.
*/

/* .walkthrough--project_toolbar--filter_typeahead:hover,
.walkthrough--project_toolbar--time_frame_dropdown:hover,
.walkthrough--project_toolbar--project_dropdown:hover,
.walkthrough--project_toolbar--saved_views_button:hover,
.walkthrough--project_toolbar--copy_shortened_link_button:hover,
.walkthrough--project_toolbar--notifications_button:hover,
.walkthrough--project_toolbar--menu_button:hover,
.walkthrough--project_toolbar--project_dropdown:hover,
.walkthrough--project_toolbar--menu_button:hover,
.walkthrough--project_toolbar_menu_dropdown--about:hover,
.walkthrough--project_toolbar_menu_dropdown--project_settings:hover,
.walkthrough--project_toolbar_menu_dropdown--organization_settings:hover,
.walkthrough--project_toolbar_menu_dropdown--invite_a_user:hover,
.walkthrough--project_toolbar_menu_dropdown--create_a_project:hover,
.walkthrough--project_toolbar_menu_dropdown--upload_an_error:hover,
.walkthrough--project_toolbar_menu_dropdown--legacy_ui:hover,
.walkthrough--project_toolbar_menu_dropdown--logout:hover,
.walkthrough--triage--table_header--add_aggregation:hover,
.walkthrough--mode_tab--overview:hover,
.walkthrough--mode_tab--triage:hover,
.walkthrough--mode_tab--explore:hover,
.walkthrough--mode_tab--debug:hover,
.walkthrough--debugger--source_code:hover,
.walkthrough--debugger--attributes:hover,
.walkthrough--debugger--system:hover,
.walkthrough--debugger--memory_map:hover,
.walkthrough--debugger--global_annotations:hover,
.walkthrough--debugger--global_variables:hover,
.walkthrough--debugger--breadcrumbs:hover,
.walkthrough--debugger--attachments:hover {
  background-color: yellow !important;
} */

/*
  Due to the dynamic nature of the overview page, several walkthrough tags are dynamic
      .walkthrough--overview--widget_[...widget title, characters only, lowercase...]

      examples:
        .walkthrough--overview--widget_summaryrow
        .walkthrough--overview--widget_errorsovertimebyversionattribute
        .walkthrough--overview--widget_errorsbyclassifiers

  
      To target a specific analyze button, you can use the widget AND analyze tag
      Example: 
        .walkthrough--overview-widget_errorsbyclassifiers .walkthrough--overview--widget_analyze
  
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
